import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Layout from '@components/layout/Layout';
import PhysicianDetails from '@components/locate-sleep-specialist/physician-details/physician-details.component';
import MapContainer from '@components/locate-sleep-specialist/map/map-container';
import AppointmentResults from '@components/locate-sleep-specialist/appointment-results/appointment-results.component';
import StepDirections from '@components/locate-sleep-specialist/step-directions/step-directions.component';
import EmailPhysicianDetails from '@components/locate-sleep-specialist/email-physician-details/email-physician-details.component';
import config from "@utils/config"

import MapPlaceholder from '@assets/images/map-placeholder.png';
import './physician-details.scss';

class PhysicianDetailsPageComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			physicianDetails: {},
			mapAreaChange: false,
			removeMarkers: true,
			clearMapDirections: true,
			viewMapMobile: false,
			classNameTest: 'pre-open-map',
			desktopWidthAcheived: false,
			setCenter: {},
		};

		this.passMapDirectionsData = this.passMapDirectionsData.bind(this);
		this.showMapMobile = this.showMapMobile.bind(this);
		this.getPhysicianBounds = this.getPhysicianBounds.bind(this);


	}

	componentDidMount() {
		if (this.props.location.state) {
			const { physicianId, markerNumber, distanceFrom } = this.props.location.state;

			this.getPhysicianDetails(physicianId, markerNumber, distanceFrom);
			this.determineMapZoomMobleDesktop();
		}
    else {
      navigate('/404/');
    }
	}

	render() {
		return (
			<Layout addedClass='physician-details-page' location={this.props.location}>
				<div className='physician-results'>
					<div className='results-wrapper'>
						<div className='desktopleft-mobilebottom'>
							<div className='results-list co-details-desktop'>
								<PhysicianDetails doctor={this.state.physicianDetails} />
							</div>
							<div>
								<EmailPhysicianDetails
									doctor={this.state.physicianDetails}
								/>
							</div>
							<div>
								<StepDirections
									doctor={this.state.physicianDetails}
									mapDirections={this.passMapDirectionsData}
								/>
							</div>
						</div>

						<div className='map-wrapper desktopright-mobiletop'>
							<div className='results-list co-details-mobile'>
								<PhysicianDetails doctor={this.state.physicianDetails} />
							</div>
							{this.state.viewMapMobile ? null : (
								<div
									className='mobile-map-placeholder'
									onClick={this.showMapMobile}>
									<h5>
										View Map <FontAwesomeIcon icon='expand' />
									</h5>
									<img src={MapPlaceholder} />
								</div>
							)}
							<div className={this.state.classNameTest}>
								<MapContainer
									mapData={[this.state.physicianDetails]}
									center={this.state.center}
									redoSearchNeeded={false}
									displayPhysicianDetails={true}
									removeMarkersNeeded={this.state.removeMarkers}
									mapDirections={this.state.mapDirections}
									clearMapDirections={this.state.clearMapDirections}
									startingLocation={this.state.startingLocation}
									setCenter={this.state.setCenter}
								/>
							</div>
						</div>
						{this.state.redoSearchNeeded ? (
							<div className='redo-search-wrapper'>
								<h4>Find Additional Specialists:</h4>
								<p>
									Click below to see Sleep Specialists on this section
									of the map.
								</p>
								<button
									className='redo-search-button'
									onClick={this.redoSearch}>
									Redo Search
								</button>
							</div>
						) : null}
					</div>

					<AppointmentResults />
				</div>
			</Layout>
		);
	}

	passMapDirectionsData(mapDirections, startingLocation) {
		this.setState({
			startingLocation: startingLocation,
			mapDirections: mapDirections,
			clearMapDirections: false,
			removeMarkers: true,
		});
	}

	getPhysicianBounds(physData) {
		const lat = physData.Lat;
		const lng = physData.Lng;
		this.setState({
			setCenter: { lat, lng },
		});
	}

	getPhysicianDetails(physicianId, markerNumber, distanceFrom) {
		let API = `${config.REACT_APP_API_HOST}/physician-finder.php/`;
		let Request = 'Details?';
		let queryString = `ServiceKey=&PhysicianId=${physicianId}`;

		fetch(API + Request + queryString)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Something went wrong ...');
				}
			})
			.then((data) => {
				this.getPhysicianBounds(data);
				data.markerNumber = markerNumber;
				data.DistanceFromRequest = distanceFrom;
				this.setState({
					physicianDetails: data,
					center: true,
				});
			})
			.catch((error) => this.setState({ error, isLoading: false }));
	}

	determineMapZoomMobleDesktop() {
		let startingWidth = window.innerWidth;
		if (startingWidth > 750) {
			this.setState({
				desktopWidthAcheived: true,
			});
		} else if (
			this.state.desktopWidthAcheived === false &&
			!this.state.viewMapMobile
		) {
			this.resizeOnWindowResize = this.resizeOnWindowResize.bind(this);
			window.addEventListener('resize', this.resizeOnWindowResize);
		}
	}

	showMapMobile() {
		this.setState(
			{
				redoSearchNeeded: false,
				removeMarkers: true,
				viewMapMobile: true,
				classNameTest: 'post-open-map',
			},
			() => {
				this.setState({
					redoSearchNeeded: false,
				});
			}
		);
	}

	resizeOnWindowResize(event) {
		if (event.target.innerWidth > 750) {
			this.setState({
				center: true,
				desktopWidthAcheived: true,
			});

			window.removeEventListener('resize', this.resizeOnWindowResize);
		}
	}
}

PhysicianDetailsPageComponent.propTypes = {
	match: PropTypes.object,
};

export default PhysicianDetailsPageComponent;
