import React, { Component } from 'react'
import CountUp from 'react-countup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'
import ReferenceComponent from '@components/references/reference.component'
import { trackGa } from '@components/GoogleTracking'
import '@components/screeners/results/results.component'
import { win } from '@src/app-utils';
import config from "@utils/config"

import pdfConversationStarter from '@assets/pdfs/narcolepsy-conversation-starter.pdf'
import calendarIcon from '@assets/images/icons/calendar-icon-blue.svg'
import printIcon from '@assets/images/icons/print-icon-purple.svg'
import './appointment-results.component.scss'

export class AppointmentResults extends Component {
  constructor() {
    super()
    this.EssScores = win && JSON.parse(localStorage.getItem('EssScores'))
    this.SnsScores = win && JSON.parse(localStorage.getItem('SnsScores'))
    this.EssScoresLength = this.EssScores ? Object.keys(this.EssScores).length : 0
    this.SnsScoresLength = this.SnsScores ? Object.keys(this.SnsScores).length : 0

    this.SnsScore = win && parseInt(localStorage.getItem('SnsScore'))
    this.EssScore = win && parseInt(localStorage.getItem('EssScore'))
    this.zipCode = win && parseInt(localStorage.getItem('placeName'))
    this.printResults = this.printResults.bind(this)
  }

  componentDidMount() {
    this.calcLeftEss()
    this.calcLeftSns()
  }

  render() {
    const references = [
      {
        content: 'Johns MW. A new method for measuring daytime sleepiness: the Epworth sleepiness scale. ',
        book: 'Sleep. ',
        info: '1991;14(6):540-545.'
      },
      {
        content: 'Sturzenegger C, Bassetti CL. The clinical spectrum of narcolepsy with cataplexy: a reappraisal. ',
        book: 'J Sleep Res. ',
        info: '2004;13(4):395-406.'
      },
      {
        content: ' Sturzenegger C, Baumann CR, Kallweit U, Lammers GJ, Bassetti CL. ',
        book:
          ' Swiss Narcolepsy Scale: a valid tool for the identification of hypocretin-1 deficient patients with narcolepsy. ',
        info: ' 2014;23(suppl 1):297. '
      },
      {
        content:
          ' Johns M, Hocking B. Excessive daytime sleepiness: daytime sleepiness and sleep habits of Australian workers. ',
        book: ' Sleep. ',
        info: '1997;20(10):844-849.'
      },
      {
        content: 'Bassetti CL. Spectrum of narcolepsy. In: Baumann CR, Bassetti CL, Scammell TE, eds. ',
        book: 'Narcolepsy: Pathophysiology, Diagnosis, and Treatment. ',
        info: 'NY: Springer Science+Business Media; 2011:309-319.'
      },
      {
        content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
        book: ' The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
        info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
      }
    ]

    return (
      <div>
        <div className="appointment-results-card">
          <img className="calendar-icon" src={calendarIcon} alt="Calendar icon" />
          <h2 className="appointment-results-header">Schedule An Appointment</h2>
          {this.EssScoresLength > 0 || this.SnsScoresLength > 0 ? (
            <div>
              <div id="results-print">
                <div className="explanation-header-wrapper">
                  <div className="explanation-wrapper">
                    <p className="appointment-results-explanation">
                      Patients with an ESS score &gt;10 and/or an SNS calculated score &lt;0 should schedule an
                      appointment with a sleep specialist and share these results at their first visit.{' '}
                    </p>
                  </div>
                  <div
                    className="print-directions"
                    onClick={() => {
                      this.printResults
                      trackGa('find sleep specialist, click, print results - specialist details')
                    }}
                  >
                    <img src={printIcon} />
                    <p>Print Results</p>
                  </div>
                </div>
                <div className="results-card post-calc">
                  <div className="score-details appointment-results-details">
                    <div className="score-value">
                      <h4>Your ESS score is:</h4>
                      <h5>{this.countUpOrDownESS()}</h5>
                      <p>
                        An ESS score &gt;10 suggests excessive daytime sleepiness.<sup>1</sup> All patients with
                        excessive daytime sleepiness should be evaluated by a sleep specialist.
                      </p>
                    </div>
                    <section className="score-chart-section">
                      <h4>
                        Interpreting ESS Scores<sup>1,4*</sup>
                      </h4>
                      <div className="chart">
                        <div className="scale-details">
                          <div className="normal-levels">
                            <p>Normal levels of sleepiness</p>
                          </div>
                          <div className="excessive-levels">
                            <p>Excessive daytime sleepiness</p>
                          </div>
                          <div className="high-levels">
                            <p>Suggests high level of EDS</p>
                          </div>
                        </div>
                        <div className="scale-values">
                          <h6 className="first-value">0</h6>
                          <h6 className="second-value">10</h6>
                          <h6 className="third-value">16</h6>
                          <h6 className="fourth-value">24</h6>
                        </div>
                        <div className="scale-line">
                          <div className="normal-line" />
                          <div className="excessive-line" />
                          <div className="high-line" />
                        </div>
                        <div className="ess-marker-holder">
                          <div className="ess-marker-verticle">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="ess-marker-horizontal-md">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="ess-marker-horizontal-lg">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="score-details sns-details">
                    <div className="score-value">
                      <h4>Your SNS score is:</h4>
                      <h5>{this.countUpOrDownSNS()}</h5>
                      <p>
                        An SNS calculated score &gt;0 is suggestive of narcolepsy with cataplexy.<sup>2,6,7</sup> All
                        patients with an SNS calculated score &lt;0 should be evaluated by a sleep specialist.
                      </p>
                    </div>
                    <section className="score-chart-section">
                      <h4>
                        Interpreting SNS Scores<sup>2,6,7*</sup>
                      </h4>
                      <div className="chart">
                        <div className="scale-details">
                          <div className="suggests-level">
                            <p>Suggests narcolepsy with cataplexy</p>
                          </div>
                          <div className="not-suggestive-level">
                            <p>Not suggestive of narcolepsy with cataplexy</p>
                          </div>
                        </div>
                        <div className="sns-scale-values">
                          <h6 className="first-value">-50</h6>
                          <h6>-40</h6>
                          <h6>-30</h6>
                          <h6>-20</h6>
                          <h6>-10</h6>
                          <h6 className="six-value">0</h6>
                          <h6 className="six-value">6</h6>
                        </div>
                        <div className="scale-line">
                          <div className="ten-line" />
                          <div className="ten-line" />
                          <div className="ten-line" />
                          <div className="ten-line" />
                          <div className="last-ten" />
                          <div className="six-line" />
                          <div className="last-line" />
                        </div>
                        <div className="sns-marker-holder">
                          <div className="sns-marker-verticle">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="sns-marker-horizontal-md">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="sns-marker-horizontal-lg">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="appointment-results-explanation">
              Ask your patients to schedule an appointment with a sleep specialist and share the results from the{' '}
              <SiteLink
                to="/screening-and-diagnosis/screeners/"
                className="inline-link-black"
                tracking="find sleep specialist, click, narcolepsy symptom screener - specialist details"
              >
                symptom screeners
              </SiteLink>{' '}
              and use the{' '}
              <a
                href={pdfConversationStarter}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-link-black"
                onClick={() => {
                  trackGa('find sleep specialist, click, narcolepsy conversation starter - specialist details')
                }}
              >
                Narcolepsy Conversation Starter
              </a>{' '}
              at their first visit.{' '}
            </p>
          )}
        </div>
        {this.EssScoresLength > 0 || this.SnsScoresLength > 0 ? (
          <div>
            <div className="primary-container results-disclaimer">
              <p>
                *These screening tools are not intended to make a narcolepsy diagnosis or replace complete evaluation by
                a sleep specialist.
              </p>
              <br />
              <p>
                The content on this site is not meant to replace a conversation with a sleep specialist. A sleep
                specialist can evaluate for symptoms and make a diagnosis. A narcolepsy diagnosis should be established
                with a clinical interview and nighttime polysomnography (PSG) followed by a multiple sleep latency test
                (MSLT).<sup>6</sup>
              </p>
            </div>
            <div className="reference-wrapper-appointment-resutls">
              <ReferenceComponent referenceList={references} />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  calcLeftEss() {
    let multiplier = 0
    let left = 0
    let score = this.EssScore
    let test = win && window.innerWidth
    let max = 0

    if (test < 750) {
      multiplier = 9.35
      left = score * multiplier
      max = 220
      this.moveEss(left, 'ess-marker-verticle', max)
    } else if (test >= 750 && test < 994) {
      multiplier = 16.5
      left = score * multiplier
      max = 390
      this.moveEss(left, 'ess-marker-horizontal-md', max)
    } else if (test >= 994) {
      multiplier = 22
      left = score * multiplier
      max = 525
      this.moveEss(left, 'ess-marker-horizontal-lg', max)
    }
  }

  calcLeftSns() {
    let multiplier = 0
    let left = 0
    let score = this.SnsScore + 50
    let test = win && window.innerWidth
    let max = 0

    if (test < 750) {
      multiplier = 4.3
      left = score * multiplier
      max = 270
      this.moveSns(left, 'sns-marker-verticle', max)
    } else if (test >= 750 && test < 994) {
      multiplier = 5.68
      left = score * multiplier
      max = 390
      this.moveSns(left, 'sns-marker-horizontal-md', max)
    } else if (test >= 994) {
      multiplier = 9
      left = score * multiplier
      max = 520
      this.moveSns(left, 'sns-marker-horizontal-lg', max)
    }
  }

  moveSns(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 270) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + 'px'
          }
        }
      }
    }
  }

  moveEss(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 220) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + 'px'
          }
        }
      }
    }
  }

  countUpOrDownESS() {
    return <CountUp start={0} end={this.EssScore} delay={1} duration={5} useEasing={true} />
  }

  countUpOrDownSNS() {
    return <CountUp start={-50} end={this.SnsScore} delay={1} duration={5} useEasing={true} />
  }

  getSymptomResultsRequest() {
    return {
      essScores: this.EssScores,
      snsScores: this.SnsScores,
      essScore: this.EssScore,
      snsScore: this.SnsScore,
      zip: this.zipCode,
      email: document.getElementById('your-email').value
    }
  }

  printResults(event) {
    event.preventDefault()

    let screenerPdfWindow = window.open(
      `${config.REACT_APP_API_HOST}/symptom-screener.php?data=${encodeURIComponent(
        JSON.stringify(this.getSymptomResultsRequest())
      )}`
    )
    screenerPdfWindow.focus()
  }
}

export default AppointmentResults
