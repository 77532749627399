import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { emailRegex } from '@assets/constants/validation'
import config from "@utils/config"

import './email-physician-details.component.scss'

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`

export class EmailPhysicianDetails extends Component {
  constructor(props) {
    super(props)

    this.handleCloseError = this.handleCloseError.bind(this)
    this.emailDetails = this.emailDetails.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.state = {
      toRecipient: false,
      ccMe: false,
      showErrorModal: false,
      errors: null,
      emailSubmitting: false,
      showMessageSent: false,
      email: '',
      fromName: '',
      toName: '',
      toEmail: '',
      canContinue: false,
    }
  }

  allowContinue() {
    this.setState({ canContinue: true })
  }
  disableContinueButton() {
    this.setState({ canContinue: false })
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false,
    })
  }

  handleInputChange(label, value) {
    this.setState({
      [label]: value,
    })
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === 'string') {
        return <p className="errors">{errors}</p>
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>
            })}
          </ul>
        )
      }
    }
  }

  selectCCMe() {
    this.setState({
      ccMe: true,
    })
  }

  emailDetails(event) {
    event.preventDefault()

    const yourEmail = document.getElementById('your-email').value.toLowerCase()

    if (!emailRegex.test(yourEmail)) {
      return
    }

    let request = this.state.toRecipient
      ? {
        From: yourEmail,
        To: document.getElementById('recipient-email').value.toLowerCase(),
        ToName: document.getElementById('recipient-name').value,
        FromName: document.getElementById('your-name').value,
        CC: this.state.ccMe,
        PhysicianId: this.props.doctor.Id,
        Subject: '',
        Message: '',
        ServiceKey: '',
      }
      : {
        From: yourEmail,
        To: yourEmail,
        ToName: '',
        FromName: '',
        CC: this.state.ccMe,
        PhysicianId: this.props.doctor.Id,
        Subject: '',
        Message: '',
        ServiceKey: '',
      }

    let messageSent = () => {
      if (this.state.showMessageSent === true) {
        setTimeout(() => {
          this.setState({ showMessageSent: false })
        }, 4000)
      }
    }
    this.setState(
      {
        emailSubmitting: true,
      },
      () => {
        fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/SharePhysician`, {
          method: 'POST',
          body: JSON.stringify(request),
        })
          .then((res) => {
            if (res.ok) {
              if (this.state.toRecipient) {
                document.getElementById('your-email').value = ''
                document.getElementById('recipient-email').value = ''
                document.getElementById('recipient-name').value = ''
                document.getElementById('your-name').value = ''
              } else {
                document.getElementById('your-email').value = ''
              }
              return res.json()
            } else {
              throw new Error('Something went wrong ...')
            }
          })
          .then((data) => {
            if (data) {
              this.setState(
                {
                  emailSubmitting: false,
                  showMessageSent: true,
                },
                () => {
                  messageSent()
                }
              )
            } else {
              this.setState({
                emailSubmitting: false,
                showErrorModal: true,
                errors: data.errors,
              })
            }
          })
      }
    )
  }

  render() {
    return (
      <div className="share-physician-info share-directions">
        {/*
        <h6 className="details-sub">Email this information:</h6>
        <div
          className={this.messageSending(this.state.showMessageSent)}
          style={this.state.toRecipient ? { height: 315 } : {}}
        >
          <img src={airplane} />
          Message Sent
        </div>
        <ValidatorForm
          onSubmit={
            this.state.canContinue
              ? this.emailDetails
              : () => {
                return
              }
          }
          noValidate
        >
          <div className="colleague-checkbox">
            <input
              type="checkbox"
              id="send-to-colleague"
              className="email-styled-checkbox"
              onChange={() => this.showRecipientEmailFields()}
            />
            <label htmlFor="send-to-colleague" className="email-check-style" />
            <label>Send this information to someone</label>
          </div>
          <TextValidator
            label="Your Email Address"
            className="input-large details-input-email"
            type="email"
            name="email"
            id="your-email"
            value={this.state.email}
            onChange={(e) => this.handleInputChange('email', e.target.value)}
            validators={['required', 'isEmail']}
            errorMessages={['Please fill out this field.', 'Your email address does not have a valid domain name.']}
          />
          {this.state.toRecipient ? (
            <div>
              <TextValidator
                label="Your Name"
                className="input-large details-input-email"
                type="text"
                name="fromName"
                id="your-name"
                value={this.state.fromName}
                onChange={(e) => this.handleInputChange('fromName', e.target.value)}
                validators={['required']}
                errorMessages={['Please fill out this field']}
              />
              <TextValidator
                label="Recipient's Email Address"
                className="input-large details-input-email"
                type="email"
                name="toEmail"
                id="recipient-email"
                value={this.state.toEmail}
                onChange={(e) => this.handleInputChange('toEmail', e.target.value)}
                validators={['required', 'isEmail']}
                errorMessages={['Please fill out this field.', 'Your email address does not have a valid domain name.']}
              />
              <TextValidator
                label="Recipient's Name"
                className="input-large details-input-email"
                type="text"
                name="toName"
                id="recipient-name"
                value={this.state.toName}
                onChange={(e) => this.handleInputChange('toName', e.target.value)}
                validators={['required']}
                errorMessages={['Please fill out this field']}
              />
              <div className="colleague-checkbox">
                <input
                  type="checkbox"
                  id="email-checkbox"
                  className="email-styled-checkbox"
                  onChange={() => this.selectCCMe()}
                />
                <label htmlFor="email-checkbox" className="email-check-style" />
                <label htmlFor="cc-me">CC me</label>
              </div>
            </div>
          ) : null}
          <ReCAPTCHA
            className="goog-recaptcha"
            sitekey={ReCAPTCHAKey}
            onChange={() => this.allowContinue()}
            onExpired={() => this.disableContinue()}
            onErrored={() => this.disableContinue()}
            required
          />
          <button
            className="email-button"
            type="submit"
            onClick={() => {
              trackGa('find sleep specialist, click, send information - specialist details')
            }}
            disabled={!this.state.canContinue}
          >
            Send Information
          </button>
        </ValidatorForm>
        <CommonModal isShowModal={this.state.showErrorModal}>
          <div className="body-content">
            <h2 className="title">Error</h2>
            <p className="content">A server error occurred while submitting your request.</p>
            {this.getErrorsFormatted(this.state.errors)}
            <div className="action">
              <button className="button btn-primary" onClick={this.handleCloseError}>
                Ok
              </button>
            </div>
          </div>
        </CommonModal>
          */}
      </div>
    )
  }

  messageSending(itSent) {
    return itSent ? 'message-sent-overlay' : 'display-none'
  }

  expandedMessageSending(isExpanded) {
    return isExpanded ? { height: 300 } : ''
  }

  showRecipientEmailFields() {
    this.setState({ toRecipient: !this.state.toRecipient })
  }
}

EmailPhysicianDetails.propTypes = {
  doctor: PropTypes.object,
}

export default EmailPhysicianDetails
