import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'

import './physician-details.component.scss'

export class PhysicianDetails extends Component {

  render() {
    return (
      <div className="physician-details-wrapper">
        <div className="physician-details-content">
          <div className="button-wrapper">
            <SiteLink to="/stay-connected/physician-results/">
              <button>
                <FontAwesomeIcon icon="angle-left" />
              </button>
            </SiteLink>
          </div>
          <div className="physician-details">
            <h1 className="physician-name">
              {this.props.doctor.First} <br /> {this.props.doctor.Last}, {this.props.doctor.ProfessionalDesignation}
            </h1>
            <div className="physician-address-wrapper">
              <p>{this.props.doctor.Address1}</p>
              <p>{this.props.doctor.Address2}</p>
              <p>
                {this.props.doctor.City}, {this.props.doctor.State} {this.props.doctor.Zip}
              </p>
            </div>
            <p className="phone-number">{this.props.doctor.Phone}</p>
            <p className="distance">{Math.round(this.props.doctor.DistanceFromRequest)} miles away</p>
            {this.props.doctor.PediatricSpecialist === true && (
              <p className="pediatric-specialist">
                <FontAwesomeIcon icon="check-circle" /> Pediatric patients welcome
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default PhysicianDetails

PhysicianDetails.propTypes = {
  doctor: PropTypes.object
}
