import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { win } from '@src/app-utils'
import config from "@utils/config"

import './step-directions.component.scss'

export class StepDirections extends Component {
  constructor() {
    super()

    this.state = {
      displayDirectionInputs: true,
      startingAddress: '',
      buttonState: 'DRIVING',
      showEmailModal: false,
      messageSending: false,
      showMessageSent: false,
      canContinue: false,
      directions: '',
      isLoading: false,
    }

    this.changeLocation = this.changeLocation.bind(this)
    this.openEmailModal = this.openEmailModal.bind(this)
    this.emailDirections = this.emailDirections.bind(this)
    this.closeEmailModal = this.closeEmailModal.bind(this)
    this.printDirections = this.printDirections.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    this.setupAutocomplete()
  }

  handleInputChange(label, value) {
    this.setState({
      [label]: value,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  allowContinue() {
    this.setState({ canContinue: true })
  }
  disableContinueButton() {
    this.setState({ canContinue: false })
  }

  render() {
    return (
      <div>
        {/*
        <div className="get-directions share-directions">
          {this.state.displayDirectionInputs ? (
            <ValidatorForm
              onSubmit={() => {
                this.calculateAndDisplayRoute('DRIVING')
                trackGa('find sleep specialist, click, get directions - specialist details')
              }}
              className="get-directions-form"
            >
              <h6 className="details-sub">Get directions from:</h6>
              <TextValidator
                label="Enter start location"
                className="input-large details-input"
                type="text"
                name="directions"
                id="pac-input"
                value={this.state.directions}
                onChange={(e) => this.handleInputChange('directions', e.target.value)}
                validators={['required']}
                errorMessages={['The address is not valid.']}
              />
              <button className="directions-button" type="submit">
                Get Directions
              </button>
            </ValidatorForm>
          ) : (
            <div className="all-directions">
              <div className="starting-address">
                <h6>Directions from</h6>
                <p dangerouslySetInnerHTML={{ __html: this.state.startingAddress }} />
                <button className="change-location-button" onClick={this.changeLocation}>
                  Change location
                </button>
              </div>
              <div className="directions-wrapper">
                <div className="transportation-mode-buttons">
                  <button
                    onClick={() => this.calculateAndDisplayRoute('DRIVING')}
                    className={this.state.buttonState === 'DRIVING' ? 'drive-button selected' : 'drive-button'}
                  >
                    <img src={carTransit} />
                    Driving
                  </button>
                  <button
                    onClick={() => this.calculateAndDisplayRoute('TRANSIT')}
                    className={this.state.buttonState === 'TRANSIT' ? 'selected' : ''}
                  >
                    <img src={busTransit} />
                    Transit
                  </button>
                  <button
                    onClick={() => this.calculateAndDisplayRoute('WALKING')}
                    className={this.state.buttonState === 'WALKING' ? 'selected' : ''}
                  >
                    <img src={walkTransit} />
                    Walking
                  </button>
                  <button
                    onClick={() => this.calculateAndDisplayRoute('BICYCLING')}
                    className={this.state.buttonState === 'BICYCLING' ? 'bike-button selected' : 'bike-button'}
                  >
                    <img src={bikeTransit} />
                    Cycling
                  </button>
                </div>
                <div id="right-panel" className="step-by-step-wrapper" />
              </div>
              <div className="email-directions" onClick={this.openEmailModal}>
                <img src={emailIcon} />
                <p>
                  <u>Email Directions</u>
                </p>
              </div>
              <div className="print-directions" onClick={this.printDirections}>
                <img src={printIcon} />
                <p>
                  <u>Print Directions</u>
                </p>
              </div>
            </div>
          )}
        </div>
        <Modal
          className="content-modal healthcare-professional-modal step-modal"
          show={this.state.showEmailModal}
          onHide={this.handleClose}
        >
          <div className="close-modal-x" onClick={() => this.closeEmailModal()}>
            X
          </div>
          <Modal.Header closeButton>
            <Modal.Title>Email Directions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content">
              <h2 className="title">Email Directions</h2>
              <div className="action">
                <div className={this.messageSending(this.state.showMessageSent)}>
                  <img src={airplane} />
                  Message Sent
                </div>
                <ValidatorForm onSubmit={() => this.emailDirections()} instantValidate={false}>
                  <TextValidator
                    label="Enter Email Address"
                    className="input-large details-input"
                    type="text"
                    name="directionEmail"
                    value={this.state.directionEmail}
                    onChange={(e) => this.handleInputChange('directionEmail', e.target.value)}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'Please fill out this field',
                      'Your email address does not have a valid domain name.',
                    ]}
                  />
                  <ReCAPTCHA
                    className="goog-recaptcha"
                    sitekey={ReCAPTCHAKey}
                    onChange={() => this.allowContinue()}
                    onExpired={() => this.disableContinue()}
                    onErrored={() => this.disableContinue()}
                    required
                  />
                  <div className="email-button-wrapper">
                    <button
                      className="button btn-primary no cancel-email-button"
                      onClick={() => this.closeEmailModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="button btn-primary send-email-button"
                      disabled={!this.state.canContinue}
                    >
                      {this.state.messageSending && <FontAwesomeIcon className="loading" icon={faSpinner} spin />} Send
                      Directions
                    </button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </Modal.Body>
        </Modal>
                  */}
      </div>
    )
  }

  setupAutocomplete() {
    let input = document.getElementById('pac-input')
    let options = {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    }

    let autocomplete = new window.google.maps.places.Autocomplete(input, options)

    new window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      var place = autocomplete.getPlace()
      localStorage.setItem('placeName', place.name)
      this.setState({directions: place.formatted_address})

      if (!place.geometry) {
        return
      }

      this.startingLocation = {
        location: {
          lat: autocomplete.getPlace().geometry.location.lat(),
          lng: autocomplete.getPlace().geometry.location.lng(),
        },
      }

      this.setState({
        startingAddress: autocomplete.getPlace().adr_address,
      })
    })
  }

  calculateAndDisplayRoute(modeOfTransportation) {
    this.setState(
      {
        displayDirectionInputs: false,
        canContinue: false,
      },
      () => {
        let directionsService = new window.google.maps.DirectionsService()
        var directionsDisplay = new window.google.maps.DirectionsRenderer()

        document.getElementById('right-panel').innerHTML = ''
        directionsDisplay.setPanel(document.getElementById('right-panel'))

        //this state update is for the buttons class but updating the state here is also what refreshes the view for the directions
        this.setState({
          buttonState: modeOfTransportation,
        })

        let destination = {
          location: {
            lat: this.props.doctor.Lat,
            lng: this.props.doctor.Lng,
          },
        }

        let that = this
        directionsService.route(
          {
            origin: this.startingLocation,
            destination: destination,
            travelMode: modeOfTransportation,
          },
          function (response, status) {
            if (status === 'OK') {
              directionsDisplay.setDirections(response)
              // pass response up to parent
              that.props.mapDirections(response, that.startingLocation)
            } else {
              window.alert('Directions request failed due to ' + status)
            }
          }
        )
      }
    )
  }

  changeLocation() {
    this.setState(
      {
        displayDirectionInputs: true,
      },
      () => {
        this.setupAutocomplete()
      }
    )
  }

  openEmailModal() {
    this.setState({
      showEmailModal: true,
    })
  }

  closeEmailModal() {
    this.setState({
      showEmailModal: false,
    })
  }

  emailDirections() {
    this.setState({
      messageSending: true,
    })

    let emailPostBody = {
      To: this.state.directionEmail,
      Directions: document.getElementById('right-panel').outerHTML,
      PhysicianId: this.props.doctor.Id,
      ReturnUrl: 'http://www.narcolepsylink.com/find-a-sleep-specialist/#approval',
      URL: 'http://www.narcolepsylink.com',
    }

    fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/SendDirections`, {
      method: 'POST',
      body: JSON.stringify(emailPostBody),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState({
            showMessageSent: true,
            canContinue: false,
          })
          this.setState(
            {
              messageSending: false,
              showMessageSent: true,
              canContinue: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showMessageSent: false,
                  canContinue: false,
                })

                this.closeEmailModal()
              }, 3800)
            }
          )
        }
      })
  }

  messageSending(itSent) {
    return itSent ? 'message-sent-overlay' : 'display-none'
  }

  printDirections() {
    const height = win && window.innerHeight
    const width = win && window.innerWidth
    var mywindow = window.open('', 'PRINT', `height=${height},width=${width}`)

    mywindow.document.write(
      '<html><head><link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet" type="text/css"><title>' +
        document.title +
        '</title></head><body><h1>' +
        document.title +
        '</h1>' +
        document.getElementById('right-panel').innerHTML +
        '</body></html>'
    )
    mywindow.document.body.setAttribute('style', 'font-family: "Open Sans", sans-serif !important')

    setTimeout(function () {
      mywindow.document.close() // necessary for IE >= 10
      mywindow.focus() // necessary for IE >= 10*/
    }, 50)

    return true
  }
}

export default StepDirections

StepDirections.propTypes = {
  doctor: PropTypes.object,
  mapDirections: PropTypes.func,
}
